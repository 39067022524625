/** @define Promo */

// Promo Ace
.Promo
{
  &--ace
  {
    padding-bottom: $cobalt-base-padding*2;
  }

  &--ace &-row
  {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
  }

  &--ace &-title
  {
    @include bplte(xs)
    {
      margin-top: $cobalt-base-padding;
    }
  }

  &--ace &-text
  {
    margin-top: $cobalt-base-padding;
  }

  &--ace &-linkWrapper
  {
    margin-top: $cobalt-base-padding;
  }

  &--ace &-imgWrapper,
  &--ace &-info
  {
    width: 100%;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    @include bpgte(sm)
    {
      width: 50%;
    }
  }

  &--ace &-imgWrapper
  {
    @include bpgte(sm)
    {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--ace &-img
  {
    flex-basis: 100%;
  }

  &--ace &-info
  {
    @include bpgte(sm)
    {
      display: inline-block;
    }
  }

  &--ace &--rtl
  {
    .Promo-row
    {
      @include bpgte(sm)
      {
        flex-direction: row-reverse;
      }
    }
  }
}
