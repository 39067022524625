/** @define Locator */
.Locator
{
  position: relative;
  padding-top: 0;
  flex-grow: 0;

  @include bpgte(md)
  {
    margin-top: 2rem;
    height: $locator-containerHeight;
  }

  &-container
  {
    position: relative;

    @include bp(md, md)
    {
      width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include bplte(sm)
    {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  &-searchBar
  {
    @include SearchBarStyle;
  }

  &-input
  {
    border: 0;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &-resultsWrapper
  {
    background-color: white;

    @include bplte(sm)
    {
      border-bottom: $border-light;
      padding-bottom: 0.5rem;
    }
  }

  &-results
  {
    border-left: $border-light;
  }

  &-initial
  {
    flex-grow: 1;
    padding: 0;
  }

  &-searchWrapper
  {
    min-width: 100%;
  }

  &-searchWrapper::after
  {
    display: none;
  }

  &-title,
  &-searchTitle,
  &-searchWrapper,
  &-initial
  {
    background-color: $gray-light;
  }

  &-title
  {
    @extend .Heading--h2;

    @include bplte(xs)
    {
      padding-top: 2rem;
    }
  }

  &-filters.is-active &-filterFieldsWrapper
  {
    @include bpgte(md)
    {
      position: fixed;
      transform: none;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      justify-content: center;
      align-items: center;
    }
  }

  &-title,
  &-searchTitle,
  &-searchWrapper
  {
    position: relative;
    flex: none;
  }

  &--ace &-content
  {
    margin-top: 1rem;
    overflow-y: hidden;

    @include bpgte(md)
    {
      width: $locator-contentWidth;
      margin-top: 0;
    }
  }

  &--ace &-resultsWrapper
  {
    overflow-y: auto;
    scrollbar-track-color: white;
    scrollbar-arrow-color: white;
    scrollbar-shadow-color: white;
    scrollbar-darkshadow-color: white;
    scrollbar-base-color: #666;

    &::-webkit-scrollbar
    {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb
    {
      background: rgba(black, 0.4);
      border-radius: 3px;

      &:hover
      {
        background: rgba(black, 0.5);
      }
    }
  }

  &--ace &-map
  {
    @include bpgte(md)
    {
      width: calc(100% - #{$locator-contentWidth});
      height: $locator-containerHeight;
      position: absolute;

      &::after
      {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 20px;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0));
      }
    }
  }

  &-input
  {
    height: auto;
  }

  &-searchTitleWithCTA
  {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: -1rem;
  }

  &-searchTitleText
  {
    margin-bottom: 1rem;
  }

  &-searchTitleCTA
  {
    @extend .Button--secondary;
    @extend .Button--fullWidthOnMobile;

    margin-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &-button
  {
    min-width: none;
    height: auto;
  }

  &-buttons &-button
  {
    height: 100%;
    width: 42px;
    background-color: white;
    border-top: $border-default;
    border-right: $border-default;
    border-bottom: $border-default;

    &:not(:last-child)
    {
      margin-right: 0;
    }
  }

  &-noResults,
  &-initial
  {
    @extend .Text--small;

    a
    {
      @extend .Link;
    }
  }

  &-promo
  {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include bplte(sm)
    {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  &-carousel
  {
    margin-bottom: 2rem;
    display: flex;

    @include bpgte(md)
    {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  &-carouselTitle
  {
    margin-top: 2rem;
    margin-bottom: 1rem;

    @extend .Heading--h2;

    @include bplte(xs)
    {
      margin-top: 64px;
    }
  }

  &-carouselItem
  {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
