/** @define Footer */

$footer-bplte: xs !default;
$footer-bpgte: sm !default;

.Footer
{
  flex-shrink: 0;
  transition: all 0.3s ease-in;

  /* postcss-bem-linter: ignore */
  .isOpen &
  {
    transform: translateY($header-translateY);
  }

  &-container
  {
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;

    &--searchBar
    {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    &--main
    {
      margin-bottom: 1rem;
    }
  }

  &--desktop &-container
  {
    @extend .l-container;

    &--main
    {
      @include bpgte($footer-bpgte)
      {
        text-align: left;
      }
    }
  }

  &-grayWrapper
  {
    background-color: $gray-light;
  }

  &-searchBarTitle
  {
    margin-bottom: 1rem;
  }

  &-row
  {
    width: 100%;
  }

  &--desktop &-row
  {
    @include bpgte($footer-bpgte)
    {
      display: flex;
      align-items: center;
    }
  }

  &-copyright,
  &-social,
  &-languages
  {
    margin-top: 1rem;
  }

  &-breadcrumbs
  {
    flex-grow: 1;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &--desktop &-breadcrumbs
  {
    @include bpgte($footer-bpgte)
    {
      margin-bottom: 0;
    }
  }

  &-copyright
  {
    flex-grow: 1;
  }

  &-social
  {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  &--desktop &-social
  {
    @include bpgte($footer-bpgte)
    {
      flex-direction: row;
      margin-left: 0;
      margin-right: 0;
      line-height: 1;
    }
  }

  &-socialNetwork
  {
    display: inline-block;
    text-align: left;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    border-top: $border-default;
  }

  &--desktop &-socialNetwork
  {
    @include bpgte($footer-bpgte)
    {
      border-top: 0;
      padding: 0;
      width: auto;
      margin-left: 12px;
      margin-top: -6px;
    }
  }

  &-socialLink
  {
    display: inline-flex;
    align-items: center;
    height: 44px;
    padding-left: 1rem;
    padding-right: 1rem;

    &:focus,
    &:active
    {
      text-decoration: underline;
    }

    &::after
    {
      content: $chevron-link;
      line-height: 1;
      margin-left: 0.5rem;
    }
  }

  &--desktop &-socialLink
  {
    @include bpgte($footer-bpgte)
    {
      height: auto;
      padding-left: 0;
      padding-right: 0;

      &::after
      {
        content: none;
      }
    }
  }

  /* postcss-bem-linter: ignore */
  &-socialLink .icon
  {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
  }

  &--desktop &-socialLink .icon
  {
    @include bpgte($footer-bpgte)
    {
      height: 22px;
      margin-right: 0;
    }
  }

  &--desktop &-socialLinkText
  {
    @include bpgte($footer-bpgte)
    {
      @include sr-only;
    }
  }

  /* postcss-bem-linter: ignore */
  &-languages select
  {
    @include Text--small;

    appearance: none;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    background-image: url('assets/images/ic-arrow-drop-down.svg');
    background-repeat: no-repeat;
    background-position: right 0.25rem center;
    padding-left: 0.25rem;
    padding-right: 1.5rem;

    /* postcss-bem-linter: ignore */
    &::-ms-expand // for IE 11 fix
    {
      display: none;
    }
  }
}
