.slick-arrow
{
  height: auto;
  width: auto;
  z-index: 1;
  background-image: none !important;

  &-image
  {
    max-height: 64px;
    max-width: 32px;
  }
}

.slick-next,
.slick-prev
{
  &::before
  {
    display: none;
  }
}

.slick-next
{
  right: 1rem !important;

  @include bpgte(md)
  {
    right: 0.9rem !important; // for some reason 1rem doesn't line up exactly
  }
}

.slick-prev
{
  left: 1rem !important;
  transform: translate(0, -50%) !important;
}

.slick-track,
.slick-list
{
  width: 100%;
}
