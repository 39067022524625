@mixin TeaserOverrideAce
{
  .Teaser.Teaser--ace &
  {
    @content;
  }
}

/** @define Teaser */

.Teaser
{
  @include Text--small;

  display: flex;
  flex-direction: column;
  border: $border-default;
  padding: 1rem;
  min-width: 260px;
  height: 100%;

  &-title,
  &-open,
  &-address,
  &-hours,
  &-phone
  {
    margin-bottom: 0.5rem;
  }

  &-phone
  {
    .Teaser--search &
    {
      margin-bottom: 1rem;
    }
  }

  &-address
  {
    .Teaser--search &
    {
      margin-bottom: 24px;
    }
  }

  &-title
  {
    @extend .Link--primary;
  }

  &-open
  {
    font-style: italic;
    display: flex;
    justify-content: space-between;
  }

  &--nearby
  {
    width: 260px;
  }

  &--search
  {
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: row;
  }

  &-services
  {
    @include TeaserOverrideAce
    {
      display: none;
    }

    /* postcss-bem-linter: ignore */
    .Teaser.Teaser--ace.Teaser--search &
    {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-servicesItem
  {
    display: inline-flex;
    align-items: center;
    margin-bottom: 4px;
    margin-right: 4px;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    height: 25px;
    background-color: $brand-primary;
  }

  &-servicesLabel
  {
    margin-bottom: 0.25rem;
    font-size: 14px;

    @include TeaserOverrideAce
    {
      margin-right: 0.5ch;
    }
  }

  &-phone
  {
    @include TeaserOverrideAce
    {
      display: block;
    }
  }

  &-directions
  {
    @include TeaserOverrideAce
    {
      display: flex;
    }

    .Teaser--search &
    {
      text-decoration: underline;
    }
  }

  &-link:first-child::before
  {
    @include TeaserOverrideAce
    {
      content: none;
    }
  }

  &-linksRow
  {
    @include TeaserOverrideAce
    {
      margin-top: auto;
      padding-top: 1rem;

      @include bplte(xs)
      {
        align-items: flex-start;
        width: 100%;
      }
    }

    .Teaser--search &
    {
      display: none;
    }
  }

  &-links
  {
    @include TeaserOverrideAce
    {
      @include bplte(xs)
      {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }
  }

  &-link
  {
    @include TeaserOverrideAce
    {
      @include bplte(xs)
      {
        margin-right: 1rem;
      }
    }
  }

  &-link a
  {
    @extend .Link;
  }

  &-miles
  {
    font-style: normal;

    @include bplte(xs)
    {
      margin-bottom: 0.5rem;
    }
  }

  &-leftCol
  {
    margin-right: 1rem;
    text-align: center;

    @include bplte(xs)
    {
      display: none;
    }
  }

  &-rightCol
  {
    flex-grow: 1;
    width: 100%;
  }

  &-pin
  {
    height: 34px;
    margin-bottom: 30px;
  }
}
