/*
Variables
*/

$modal-overlay-color: rgba(0, 0, 0, 0.5);
$modal-content-background: #fefefe !default;
$modal-content-border: 1px solid #888 !default;

.c-Modal
{
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  &-content
  {
    background-color: $modal-content-background;
    margin: auto;
    padding: 20px;
    border: $modal-content-border;
    width: 80%;
  }

  &-open
  {
    display: block;
  }

  &-overlay
  {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $modal-overlay-color;
    z-index: 2;
    cursor: pointer;
  }

  &-closeButton
  {
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
}
