/** @define Promo */
.Promo
{
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  color: white;

  @include bpgte(sm)
  {
    padding-left: 98px;
    padding-right: 98px;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @include bplte(xs)
  {
    padding: 24px;
    flex-direction: column;
    align-items: center;
  }

  &-content
  {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include bpgte(sm)
    {
      width: 50%;
      margin-right: 1rem;
    }
  }

  &-image
  {
    display: flex;
    align-items: center;
    margin-top: 24px;
    width: 100%;
    order: -1;

    @include bpgte(sm)
    {
      margin-left: 1rem;
      margin-top: 0;
      width: 50%;
      order: 0;
    }

    @include bplte(xs)
    {
      justify-content: center;
    }
  }

  &-title
  {
    @extend .Heading--h1;
    margin-bottom: 1rem;

    @include bplte(xs)
    {
      @include Heading--h2;
    }
  }

  &-description
  {
    @extend .Text--small;
  }

  &-button
  {
    margin-top: 1rem;
  }

  &-link
  {
    @include bplte(xs)
    {
      width: 100%;
    }
  }
}
