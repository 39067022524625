@mixin l-container
{
  margin-left: auto;
  margin-right: auto;
  padding-left: $grid-gutter-width-xs/2;
  padding-right: $grid-gutter-width-xs/2;
  width: 100%;

  @include bpgte(sm)
  {
    width: $container-tablet-base;
    padding-left: $grid-gutter-width-sm/2;
    padding-right: $grid-gutter-width-sm/2;
  }

  @include bpgte(md)
  {
    width: $container-desktop-base;
    padding-left: $grid-gutter-width-md/2;
    padding-right: $grid-gutter-width-md/2;
  }

  @include bpgte(lg)
  {
    width: $container-large-desktop-base;
    padding-left: $grid-gutter-width-lg/2;
    padding-right: $grid-gutter-width-lg/2;
  }
}

.sr-only-xs
{
  @include bplte(xs)
  {
    @include sr-only;
  }
}
