// common theming goes here, e.g. global component styling
.c-phone-number-link
{
  @include Link--default;
}

.c-bread-crumbs
{
  margin-top: -6px;
  margin-bottom: -6px;
}

.c-bread-crumbs-item
{
  @include font-sizes(12px, 1.5);

  padding-top: 6px;
  padding-bottom: 6px;

  & + &::before
  {
    font-weight: $font-weight-bold;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  a
  {
    font-weight: $font-weight-bold;

    &:hover,
    &:focus
    {
      text-decoration: underline;
    }

    &:active
    {
      font-weight: $font-weight-regular;
    }
  }
}

.search
{
  &-input
  {
    border-radius: inherit;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 11px;
    padding-bottom: 11px;

    &::placeholder
    {
      color: #cdcdcd;
      opacity: 1;
    }
  }

  &-button
  {
    border-radius: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .icon
    {
      font-size: 18px;
      margin: 0.5rem;
    }
  }

  &--footer
  {
    @include SearchBarStyle;

    max-width: 360px;
    border-radius: 0.25rem;
    margin-left: auto;
    margin-right: auto;
  }

  &--footer &-input
  {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
