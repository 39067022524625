/** @define Footer */

$icon-height: 44px !default;

@mixin Footer($bp)
{
  &--ace
  {
    padding-top: $cobalt-base-padding/2;
    padding-bottom: $cobalt-base-padding/2;
  }

  &-linksWrapper
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;

    @include bpgte($bp)
    {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
    }
  }

  &-social
  {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-top: $cobalt-base-padding/2;

    @include bpgte($bp)
    {
      margin-top: 0;
    }
  }

  &-links
  {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    line-height: 44px;

    @include bpgte($bp)
    {
      flex-direction: row;
      align-items: center;
    }
  }

  &-link
  {
    @include bpgte($bp)
    {
      margin-right: $cobalt-base-padding;
    }

    &:not(:last-child)
    {
      margin-bottom: $cobalt-base-padding/8;

      @include bpgte($bp)
      {
        margin-bottom: 0;
      }
    }
  }

  &-text
  {
    text-align: center;
    margin-top: $cobalt-base-padding/2;

    @include bpgte($bp)
    {
      text-align: left;
    }
  }
}

.Footer--ace
{
  .icon
  {
    height: $icon-height;
    width: $icon-height;
  }

  .Footer
  {
    @include Footer(md);
  }
}

.Footer--mobile
{
  .Footer
  {
    @include Footer(max);
  }
}
