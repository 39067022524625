@mixin Link--default
{
  cursor: pointer;
  text-decoration: underline;

  &:hover,
  &:active
  {
    text-decoration: none;
  }
}

@mixin Link--underlineOnHoverActive
{
  text-decoration: none;

  &:hover,
  &:active
  {
    text-decoration: underline;
  }
}

@mixin Link--slideUnderline($parentLinkClass: null)
{
  text-decoration: none;
  display: inline-block;

  &::after
  {
    content: "";
    width: 0;
    display: block;
    margin-bottom: -2px;
    border-bottom: 2px solid;
    transition: width 300ms ease;
  }

  &:hover,
  &:active
  {
    text-decoration: none;

    &::after
    {
      width: 100%;
    }
  }

  @if ($parentLinkClass != null)
  {
    #{$parentLinkClass}:hover,
    #{$parentLinkClass}:active
    {
      text-decoration: none;
    }

    #{$parentLinkClass}:hover &::after,
    #{$parentLinkClass}:active &::after
    {
      width: 100%;
    }
  }
}

@mixin Link--chevron
{
  @include Text--primaryLink;

  &::after
  {
    content: "";
    display: inline;
    margin-left: 0.5rem;
    background: $chevron-link no-repeat;
    padding-right: 0.5rem; // width of chevron
  }
}

@mixin Link--chevronSlideOnHover
{
  @include Link--chevron;

  &::after
  {
    padding-right: 1.5rem; // width of chevron + 1rem
    transition: background-position 0.2s ease-in;
    background-position: left;
  }

  &:hover::after
  {
    // chevron slides to the right by 1rem
    background-position: right;
  }
}

@mixin Link--primary
{
  @include font-sizes(16px, 24px);
  @include underlineReverse;

  font-family: $font-family-secondary;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

@mixin underlineReverse
{
  text-decoration: underline;

  /* postcss-bem-linter: ignore */
  &:hover
  {
    text-decoration: none;
  }
}

/** @define Link */

.Link
{
  @include Link--default;

  &--plain
  {
    color: inherit;

    &:link,
    &:hover,
    &:visited,
    &:active,
    &:focus
    {
      color: inherit;
    }
  }

  &--underlined
  {
    text-decoration: underline;
  }

  &--slideUnderline
  {
    @include Link--slideUnderline;
  }

  &--chevron
  {
    @include Link--chevron;
  }

  &--primary
  {
    @include Link--underlineOnHoverActive;
    @include Link--chevronSlideOnHover;
  }
}
