/** @define Header */

$header-bpgte: sm !default;
$header-bplte: xs !default;
$header-backgroundColor: white !default;
$header-color: $text-primary !default;
$header-boxShadow-bpgte: 0 2px 8px 0 rgba(0, 0, 0, 0.2) !default;
$header-logoHeight: 50px;
$header-logoHeight-desktop: 74px;
$header-logoWrapperWidth: 175px;
$header-logoWrapperWidth-desktop: 299px;

$header-logo-bpgte: md;
$linkHeightMobile: 42px;

.Header
{
  color: $header-color;
  background-color: $header-backgroundColor;
  z-index: 5;

  @include bpgte($header-bpgte)
  {
    box-shadow: $header-boxShadow-bpgte;
  }

  @include bplte(xs)
  {
    height: $header-height-mobile;
  }

  &-main
  {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include bpgte($header-bpgte)
    {
      flex-wrap: nowrap;
    }
  }

  &-logoWrapper
  {
    height: $header-logoHeight;
    width: $header-logoWrapperWidth;

    @include bpgte($header-logo-bpgte)
    {
      height: $header-logoHeight-desktop;
      width: $header-logoWrapperWidth-desktop;
    }
  }

  &-outerWrap
  {
    @include bplte($header-bplte)
    {
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: white;
      border-bottom: $border-default;
      z-index: 1;
    }
  }

  &-toggle
  {
    background-color: $gray-dark;
    width: 50px;

    @include bpgte($header-bpgte)
    {
      display: none;
    }
  }

  &-hamburger
  {
    /* postcss-bem-linter: ignore */
    .isOpen &
    {
      display: none;
    }
  }

  &-close
  {
    display: none;

    /* postcss-bem-linter: ignore */
    .isOpen &
    {
      display: inline;
    }
  }

  &-links
  {
    display: flex;
    align-items: center;

    &--main
    {
      margin-left: auto;

      @include bplte($header-bplte)
      {
        order: 2;
        flex-basis: 100%;
        flex-direction: column;
        margin-left: 0;
      }
    }

    &--extra
    {
      @include bplte($header-bplte)
      {
        order: 1;
        flex-basis: 100%;
        flex-direction: column;
      }
    }

    &--main,
    &--extra
    {
      @include bplte($header-bplte)
      {
        .Header-menuItem
        {
          width: 100%;
          display: flex;
          align-items: center;
          border-bottom: $border-default;
          height: $linkHeightMobile;
        }
      }
    }
  }

  &-menuItem
  {
    @include font-sizes(18px, 22px);

    font-family: $font-family-header;
    letter-spacing: 0.8px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @include bpgte($header-bpgte)
    {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &-linksWrapper
  {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &--mobile
    {
      @include bpgte($header-bpgte)
      {
        display: none;
      }

      @include bplte($header-bplte)
      {
        flex-direction: column;
        transform: translateY(-$header-translateY);
        transition: all 0.3s ease-in;

        /* postcss-bem-linter: ignore */
        .isOpen &
        {
          transform: translateY(0);
        }
      }
    }

    &--desktop
    {
      @include bplte($header-bplte)
      {
        display: none;
      }
    }
  }

  &-linkText
  {
    display: inline-flex;
    align-items: center;

    &--slideUnderline
    {
      @include Link--slideUnderline($parentLinkClass: '.Header-link');
    }
  }

  &-locatorIconWrapper
  {
    margin-top: -2px;
    margin-bottom: -2px;
    margin-left: 0.5rem;
    height: 24px;
    width: 24px;
    font-size: 18px; // height/width of icon
    display: flex;
    align-items: center;
    justify-content: center;

    /* postcss-bem-linter: ignore */
    .icon
    {
      height: 19px;
    }
  }
}
