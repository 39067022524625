// search level theming goes here
html.cobalt body
{
  &.is-overflowHidden
  {
    @include bpgte(md)
    {
      overflow-y: scroll;
      position: fixed;
    }
  }
}

.Main
{
  @include bpgte(sm)
  {
    padding-top: 0;
  }
}

.Filters-legend
{
  font-weight: $font-weight-bold;
  margin-bottom: 1rem;
}

.search
{
  flex-wrap: nowrap;
}

.ResultSummary
{
  @extend .Text--small;
}

.custom-icon-button.is-hovered,
.custom-icon-button.is-selected
{
  .stroke
  {
    opacity: 1;
    stroke: $text-primary;
    stroke-width: 1;
  }

  .background
  {
    fill: white;
  }

  .text
  {
    fill: $text-primary;
  }
}

html.ace
{
  .Header
  {
    @include bpgte(md)
    {
      position: relative;
    }
  }
}

html.cobalt
{
  .SpinnerModal
  {
    position: absolute;
    height: auto;
    top: 0;

    @include bpgte(md)
    {
      left: 1rem;
      height: $locator-containerHeight;
      width: $locator-contentWidth;
      top: 2rem;
    }
  }
}

html.cobalt .custom-icon-button.is-selected
{
  transform: none;
}

.slick-next
{
  right: 1rem;
}

.slick-prev
{
  left: 1rem;
}

.Footer-grayWrapper
{
  display: none;
}

html.cobalt
{
  .Header
  {
    min-height: $header-height-mobile;

    @include bp(sm,sm)
    {
      min-height: $header-height-tablet;
    }

    @include bpgte(md)
    {
      min-height: $header-height-desktop;
    }
  }
}
