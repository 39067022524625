/** @define Locator */

$header-height-mobile: 50px !default;
$header-height-tablet: 50px !default;
$header-height-desktop: 100px !default;
$footer-height-desktop: 100px !default;
$results-width: 584px !default;
$results-width-large: 944px !default;
$desktop-break-point-min: md;
$mobile-break-point-max: sm;

.Locator
{
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;

  @include bpgte($desktop-break-point-min)
  {
    display: flex;
  }

  &-title
  {
    padding: 16px;
  }

  &-buttons
  {
    display: flex;
  }

  &-button
  {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 44px;
    height: 44px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 8px;
    padding-right: 8px;

    &[disabled]
    {
      background-color: #e7e7e7;
      cursor: default;
    }

    &:not(:last-child)
    {
      margin-right: 1px;
    }

    &:not(:first-child)
    {
      margin-left: 1px;
    }

    /* postcss-bem-linter: ignore */
    .icon
    {
      width: 18px;
      height: 18px;
    }
  }

  &-searchBar
  {
    display: flex;
    flex-grow: 1;
  }

  &-input
  {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    border: $border-default;
    height: 44px;
    width: 100%;
  }

  &-searchTitle
  {
    padding-left: 16px;
    padding-right: 16px;
  }

  &-results
  {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: auto;
  }

  &-searchWrapper
  {
    position: sticky;
    z-index: 1;
    padding: 16px;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    top: 0;
    background-color: white;

    &::after
    {
      content: "";
      z-index: -1;
      display: block;
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -3px;
      left: 0;
      background:
        linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0)
        );
    }
  }

  &-overlay
  {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    z-index: 2;

    &.is-active
    {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear, opacity 0.3s linear;
    }
  }

  &-resultsWrapper
  {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-resultsSummary
  {
    padding: 16px;
  }

  &-content
  {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: white;

    @include bpgte($desktop-break-point-min)
    {
      position: relative;
      bottom: 0;
      height: auto;
      width: $results-width;
    }
  }

  &-initial,
  &-noResults
  {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 80%;
  }

  /* postcss-bem-linter: ignore */
  &-filterFieldsWrapper
  {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;
    z-index: 12;
  }

  /* postcss-bem-linter: ignore */
  &-filters.is-active
  {
    /* postcss-bem-linter: ignore */
    .Locator-filterFieldsWrapper
    {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 0.3s linear;
    }
  }

  &-filterFields
  {
    width: 100%;
  }

  &-filtersCount
  {
    margin-left: 3px;
  }

  &-form
  {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

  /* postcss-bem-linter: ignore */
  .c-map-with-pins-wrapper,
  .c-map-with-pins
  {
    height: 100%;
    width: 100%;
  }
}

.Locator--ace
{
  @include bpgte(md)
  {
    position: fixed;
    padding-top: $header-height-desktop;
  }

  .Locator
  {
    &-content
    {
      @include bpgte($desktop-break-point-min)
      {
        overflow-y: scroll;
      }
    }

    &-map
    {
      display: none;

      @include bpgte(md)
      {
        display: block;
        position: fixed;
        width: calc(100% - #{$results-width});
        height: calc(100vh - #{$header-height-desktop});
        right: 0;
      }
    }

    /* postcss-bem-linter: ignore */
    &-filterFieldsWrapper
    {
      @include bpgte(md)
      {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translate(-50%, -50%);
      }
    }

    &-filterFields
    {
      @include bpgte(md)
      {
        width: 400px;
        min-height: 252px;
        max-height: 460px;
      }
    }

    &-overlay
    {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

.Locator--chaz
{
  @include bpgte($desktop-break-point-min)
  {
    flex-direction: row;
  }

  .Locator
  {
    &-searchBar
    {
      display: flex;
      position: relative;
      flex-grow: 1;

      /* postcss-bem-linter: ignore */
      .search-button
      {
        position: absolute;
        right: 0;
        border-left: none;
        z-index: 10;
      }
    }

    &-input
    {
      padding-left: $cobalt-base-padding/2;
    }

    &-map
    {
      display: none;

      @include bpgte($desktop-break-point-min)
      {
        display: block;
        position: sticky;
        width: 34%;
        height: 100vh;
        top: 0;
      }

      @include bpgte(lg)
      {
        width: 34%;
        flex-grow: 1;
      }
    }

    /* postcss-bem-linter: ignore */
    &-map.js-is-stuck,
    &-map.js-is-sticky
    {
      height: calc(100vh - #{$footer-height-desktop});
      width: 100%;
    }

    // width adjustment for sticky polyfill behavior in IE
    &-searchWrapper.js-is-stuck,
    &-searchWrapper.js-is-sticky,
    &-content
    {
      @include bpgte($desktop-break-point-min)
      {
        width: 66%;
        max-width: $results-width-large;
      }
    }

    /* postcss-bem-linter: ignore */
    &-filterFieldsWrapper
    {
      @include bpgte(md)
      {
        left: 0;
        top: 0;
        bottom: 0;
        right: auto;
        width: 66%;
        max-width: $results-width-large;
      }
    }

    &-filterFields
    {
      @include bpgte($desktop-break-point-min)
      {
        height: 100%;
      }
    }

    &-overlay
    {
      opacity: 0.05;
      background-color: #808080;

      /* IE11 fallback for blur */
      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)
      {
        background-color: black;
        opacity: 0.4;
      }
    }
  }
}
