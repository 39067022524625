/** @define Products */

// Products Ace
.Products
{
  &--ace
  {
    padding-bottom: $cobalt-base-padding*2;
  }

  &--ace &-row
  {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;

    &--center
    {
      @include bpgte(md)
      {
        justify-content: center;
      }
    }
  }

  &--ace &-title,
  &--ace &-linkWrapper
  {
    width: 100%;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }

  &--ace &-title
  {
    text-align: center;
  }

  &--ace &-list
  {
    margin-top: $cobalt-base-padding;
  }

  &--ace &-listItem
  {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    @include bplte(xs)
    {
      width: 100%;

      &:not(:last-child)
      {
        margin-bottom: $cobalt-base-padding;
      }
    }
  }

  &--ace &-row--center &-listItem
  {
    @include bpgte(sm)
    {
      width: percentage(1 / 3);
    }
  }

  &--ace &-row--widen &-listItem
  {
    @include bpgte(sm)
    {
      flex: 1 1 0;
    }
  }

  &--ace &-linkWrapper
  {
    text-align: center;
    margin-top: 2rem;
  }
}
