/** @define Teaser */

.Teaser--ace
{
  .Teaser
  {
    &-linksRow,
    &-links
    {
      display: flex;
      align-items: center;
    }

    &-linksRow
    {
      flex-wrap: nowrap;

      @include bplte(xs)
      {
        flex-direction: column;
      }
    }

    &-links
    {
      @include bplte(xs)
      {
        flex-direction: column;
      }
    }

    &-link
    {
      display: flex;

      &::before
      {
        @include bplte(xs)
        {
          display: none;
        }

        content: "|";
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
      }
    }

    &-directions,
    &-phone
    {
      display: none;
    }

    &-services
    {
      display: flex;
    }

    &-servicesLabel
    {
      font-weight: $font-weight-bold;
      margin-right: 1ch;
    }
  }
}

.Teaser--ace.Teaser--short
{
  .Teaser
  {
    &-services,
    &-cta
    {
      display: none;
    }
  }

  /* postcss-bem-linter: ignore */
  .c-AddressRow:not(:first-child)
  {
    display: none;
  }
}

.Teaser--chaz
{
  .Teaser
  {
    &-titleWrapper
    {
      display: flex;
      justify-content: space-between;
      margin-bottom: $cobalt-base-padding/2;
    }

    &-content
    {
      @include bpgte($desktop-break-point-min)
      {
        display: flex;
      }
    }

    &-column
    {
      @include bpgte($desktop-break-point-min)
      {
        flex-basis: 50%;
      }
    }

    &-open
    {
      font-weight: $font-weight-bold;
    }

    &-miles
    {
      margin-left: 8px;
    }

    &-phone
    {
      padding-bottom: $cobalt-base-padding;
      font-weight: $font-weight-bold;
    }

    &-services
    {
      display: flex;
      margin-top: $cobalt-base-padding/2;

      @include bpgte($desktop-break-point-min)
      {
        margin-top: 0;
      }
    }

    &-servicesLabel
    {
      font-weight: $font-weight-bold;
      margin-right: 1ch;
    }
  }
}
