
@mixin Button
{
  @include Text--base;

  cursor: pointer;
  appearance: none;
  display: inline-block;
  text-decoration: none;
  transition: all 200ms ease-out;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  text-align: center;
  min-width: 120px;

  @include bpgte(sm)
  {
    max-width: 320px;
  }

  &:link,
  &:visited,
  &:active,
  &:hover,
  &:focus
  {
    text-decoration: none;
  }
}

@mixin Button--primary
{
  background-color: $brand-primary;
  color: black;

  &:not([disabled]):hover
  {
    box-shadow: $button-primary-boxShadow;
    transform: translate(-2px, -2px);
  }
}

@mixin Button--secondary
{
  background-color: $brand-secondary;
  color: white;

  &:not([disabled]):hover
  {
    box-shadow: $button-secondary-boxShadow;
  }

  /* postcss-bem-linter: ignore */
  &:hover
  {
    transform: translate(-2px, -2px);
    box-shadow: $shadow-button;
  }
}

@mixin Button--tertiary
{
  @include font-sizes(16px, 16px);
  display: inline-block;
  text-transform: uppercase;
  transition: $transition-default;
  font-weight: $font-weight-bold;
  font-family: $font-family-secondary;
  border: $border-white;
  color: $white;
  padding: 12px 30px;
  border-radius: 4px;

  /* postcss-bem-linter: ignore */
  &:hover
  {
    background-color: $white;
    color: $black-button;
  }
}

/** @define Button */

.Button
{
  @include Button;

  &--clear
  {
    background-color: transparent;
  }

  &--primary
  {
    @include Button;
    @include Button--primary;
  }

  &--secondary
  {
    @include Button;
    @include Button--secondary;
  }

  &--tertiary
  {
    @include Button;
    @include Button--tertiary;
  }

  &--tight
  {
    padding-top: 1/3 * 1em;
    padding-bottom: 1/3 * 1em;
    padding-left: 2/3 * 1em;
    padding-right: 2/3 * 1em;
  }

  &--loose
  {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1em;
    padding-left: 1em;
  }

  &--fullWidthOnMobile
  {
    @include bplte(xs)
    {
      width: 100%;
    }
  }
}
