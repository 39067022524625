/** @define ResultList */

.ResultList
{
  padding-left: 1rem;
  padding-right: 1rem;

  &-item
  {
    border: $border-default;
    padding: 1rem;
    margin-bottom: 0.5rem;

    &:hover,
    &.is-hovered
    {
      @include bpgte(md)
      {
        background-color: $gray-light;
      }
    }

    &.is-selected
    {
      @include bpgte(md)
      {
        background-color: $gray-light;
        border-color: $gray-dark;
        border-width: 2px;
        padding: calc(1rem - 1px);
      }
    }

    &--ordered
    {
      @include bpgte(md)
      {
        position: relative;
      }
    }
  }
}
