/** @define Main */

.Main
{
  @include bplte(xs)
  {
    transition: all 0.3s ease-in;
    z-index: 5;
  }

  @include bpgte(sm)
  {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    padding-top: 2rem;
  }

  /* postcss-bem-linter: ignore */
  .isOpen &
  {
    transform: translateY($header-translateY);
  }
}
