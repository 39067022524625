/** @define Directory */

.Directory
{
  &-listLinkText
  {
    @include Text--primaryLink;
  }

  &-title
  {
    margin-bottom: 32px;
  }

  &-listLinks
  {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }

  &-listLink
  {
    width: 25%;
    margin: 8px 0;

    &:hover
    {
      text-decoration: underline;
    }

    @include bplte(sm)
    {
      width: 50%;
    }

    @include bplte(xs)
    {
      width: 100%;
    }
  }
}
