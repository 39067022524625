/** @define Text */

@mixin Text--base
{
  @include font-sizes(14px, 1.58);

  font-family: $font-family-main;
}

@mixin Text--large
{
  @include font-sizes(18px, 27px);
}

@mixin Text--small
{
  @include font-sizes(12px, 1.5);
}

@mixin Text--primaryLink
{
  @include font-sizes(16px, 1.5);

  font-family: $font-family-main;
  font-weight: $font-weight-bold;
}

.Text
{
  @include Text--base;

  &--large
  {
    @include Text--large;
  }

  &--small
  {
    @include Text--small;
  }

  &--bold
  {
    font-weight: $font-weight-bold;
  }

  &--semibold
  {
    font-weight: $font-weight-semibold;
  }

  &--medium
  {
    font-weight: $font-weight-medium;
  }

  &--light
  {
    font-weight: $font-weight-light;
  }

  &--italic
  {
    font-style: italic;
  }
}
