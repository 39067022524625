/** @define About */

// About Ace
.About
{
  &--ace
  {
    padding-bottom: $cobalt-base-padding*2;
  }

  &--ace &-row
  {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
  }

  &--ace &-title,
  &--ace &-content,
  &--ace &-buttonWrapper
  {
    width: 100%;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }

  &--ace &-title
  {
    text-align: center;
  }

  &--ace &-content
  {
    margin-top: $cobalt-base-padding;
  }

  &--ace &-description
  {
    @include bp(sm)
    {
      width: percentage(2 / 3);
    }

    @include bpgte(sm)
    {
      margin-left: auto;
      margin-right: auto;
    }

    @include bpgte(md)
    {
      width: percentage(1 / 2);
    }
  }

  &--ace &-buttonWrapper
  {
    text-align: center;
    margin-top: $cobalt-base-padding;
  }
}
