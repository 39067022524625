// This file contains styling mixins that are provided for convenience
// They are meant to be overridden or replaced

// Utility Mixins:

@import '../components/Util/UtilityMixins';

@mixin bottomBorderOnMobile
{
  @include bplte(xs)
  {
    border-bottom: 1px $gray-light solid;
  }
}

@mixin SearchBarStyle
{
  @include Text--small;

  background-color: white;
  border: $border-default;
  flex-wrap: nowrap;
}

@mixin html-styles
{
  i,
  em
  {
    font-style: italic;
  }

  b,
  strong
  {
    font-weight: $font-weight-bold;
  }

  a
  {
    text-decoration: underline;

    &:hover
    {
      text-decoration: none;
    }
  }

  ol
  {
    list-style-type: decimal;
  }

  ul
  {
    list-style: disc;
  }

  ol,
  ul
  {
    list-style-position: inside;
  }

  li
  {
    margin-left: 25px;
  }
}

@mixin icon($icon, $width, $height)
{
  width: $width;
  height: $height;
  background-image: url($icon);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

@mixin icon-before($icon, $width, $height, $marginRight)
{
  display: flex;
  align-items: center;

  &::before
  {
    @include icon($icon, $width, $height);
    content: "";
    margin-right: $marginRight;
  }
}

@mixin icon-after($icon, $width, $height, $marginLeft)
{
  display: flex;
  align-items: center;

  &::after
  {
    @include icon($icon, $width, $height);
    content: "";
    margin-left: $marginLeft;
  }
}
