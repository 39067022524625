/** @define CarouselItem */
.CarouselItem
{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-light;

  @include bpgte(sm)
  {
    height: 195px;
  }

  @include bplte(xs)
  {
    height: 216px;
  }

  @include bpgte(lg)
  {
    width: 260px;
  }

  &-link
  {
    display: flex;
    height: 100%;

    @include bpgte(md)
    {
      display: block;
      height: auto;
    }
  }

  &-image
  {
    margin: auto;

    @include bpgte(md)
    {
      margin: 0;
    }
  }
}
