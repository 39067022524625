/** @define LocatorFilters */

.LocatorFilters
{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &-titleRow
  {
    @extend .Heading--h2;
    border-bottom: $border-light;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &-buttons
  {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: -0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-top: 1px $gray-light solid;
  }

  &-content
  {
    overflow-y: auto;
  }

  &-button
  {
    @include Button;
    @include Button--secondary;

    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: none;

    @include bpgte(sm)
    {
      max-width: none;
    }

    &:last-child
    {
      @include Button--primary;

      @include bpgte(sm)
      {
        max-width: none;
      }
    }

    &[disabled],
    &[disabled]:hover
    {
      background-color: $gray-medium;
      opacity: 0.7;
    }

    &:not(:first-child)
    {
      margin-left: 1rem;
    }
  }
}
