/** @define LocatorFilters */

.LocatorFilters
{
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;

  &-content
  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label
    {
      margin-bottom: 20px;
    }
  }

  &-titleRow
  {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    font-weight: $font-weight-bold;
    margin-bottom: 16px;
  }

  &-content
  {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 1px;

    label
    {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      margin-bottom: 20px;
    }

    input
    {
      margin-right: 8px;
    }

    select
    {
      height: 44px;
    }
  }

  &-close
  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    margin-left: 8px;

    &:focus
    {
      @include bpgte($desktop-break-point-min)
      {
        outline: 1px dashed black;
      }
    }

    &::-moz-focus-inner
    {
      outline: 0;
      border: 0;
    }
  }

  &-buttons
  {
    display: flex;
    flex-shrink: 0;
    padding-top: 8px;
  }

  &-button
  {
    display: flex;
    flex-basis: 50%;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    &[disabled],
    &:hover[disabled]
    {
      background-color: #e7e7e7;
      cursor: default;
    }

    &:not(:first-child)
    {
      margin-left: $cobalt-base-padding/2;
    }
  }
}
