/** @define Heading */

@mixin Heading
{
  font-family: $font-family-headings;
  font-weight: $font-weight-medium;
}

@mixin Heading--h1
{
  @include font-sizes(32px, 40px, 32px, 38px);
  @include Heading;
}

@mixin Heading--h2($font-size: 28px, $line-height: 34px)
{
  @include font-sizes($font-size, $line-height, 22px, 26px);
  @include Heading;
}

@mixin Heading--h2Large
{
  @include Heading--h2(32px, 40px);
}

@mixin Heading--h3($font-size: 20px, $line-height: 24px)
{
  @include font-sizes($font-size, $line-height, 18px, 20px);
  @include Heading;
}

@mixin Heading--h3Large
{
  @include Heading--h3(28px, 34px);
}

@mixin Heading--h4
{
  @include font-sizes(14px, 20px);

  font-family: $font-family-main;
  font-weight: $font-weight-bold;
  margin-bottom: 1rem;
}

@mixin Heading--base
{
  font-family: $font-family-headings;
  font-weight: $font-weight-light;
}

@mixin Heading--lead
{
  @include font-sizes(52px, 56px, 40px, 48px);
  @include Heading--base;
}

@mixin Heading--head
{
  @include font-sizes(34px, 40px, 26px, 31px);
  @include Heading--base;
}

.Heading
{
  @include Heading;

  &--h1
  {
    @include Heading--h1;
  }

  &--h2
  {
    @include Heading--h2;
  }

  &--h2Large
  {
    @include Heading--h2Large;
  }

  &--h3
  {
    @include Heading--h3;
  }

  &--h3Large
  {
    @include Heading--h3Large;
  }

  &--h4
  {
    @include Heading--h4;
  }

  &--lead
  {
    @include Heading--lead;
  }

  &--head
  {
    @include Heading--head;
  }
}
