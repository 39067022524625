/** @define Nearby */

// Nearby Ace
.Nearby
{
  &--ace
  {
    padding-bottom: $cobalt-base-padding*2;
  }

  &--ace &-row
  {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;

    &--center
    {
      @include bpgte(md)
      {
        justify-content: center;
      }
    }
  }

  &--ace &-title
  {
    width: 100%;
    text-align: center;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }

  &--ace &-locs
  {
    margin-top: $cobalt-base-padding;
  }

  &--ace &-loc
  {
    padding-bottom: $cobalt-base-padding;

    @include bplte(xs)
    {
      width: 100%;
      margin-left: $cobalt-base-padding/2;
      margin-right: $cobalt-base-padding/2;

      &:not(:last-child)
      {
        border-bottom: $border-default;
        margin-bottom: $cobalt-base-padding;
      }
    }

    @include bp(sm)
    {
      width: percentage(1 / 2);
    }

    @include bpgte(sm)
    {
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
    }
  }

  &--ace &-row--center &-loc
  {
    @include bpgte(md)
    {
      width: 25%;
    }
  }

  &--ace &-row--widen &-loc
  {
    @include bpgte(md)
    {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
    }
  }

  &--ace &-linkWrapper
  {
    width: 100%;
    text-align: center;
    padding-left: $cobalt-base-padding/2;
    padding-right: $cobalt-base-padding/2;
  }
}
