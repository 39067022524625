/** @define NAP */

// NAP Ace
.NAP
{
  &--ace
  {
    padding-top: $cobalt-base-padding;
    padding-bottom: $cobalt-base-padding;

    @include bpgte(sm)
    {
      padding-top: $cobalt-base-padding*2;
      padding-bottom: $cobalt-base-padding*2;
    }
  }

  &--ace &-row
  {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
  }

  &--ace &-row1
  {
    padding-bottom: $cobalt-base-padding/2
  }

  &--ace &-buttons,
  {
    display: flex;
    flex-direction: column;
    margin-top: $cobalt-base-padding;

    @include bp(sm,sm)
    {
      flex-direction: row;
    }

    @include bpgte(lg)
    {
      flex-direction: row;
    }
  }

  &--ace &-uber
  {
    @include bpgte(sm)
    {
      display: none;
    }
  }

  &--ace &-button
  {
    cursor: pointer;

    &:not(:first-child)
    {
      margin-top: $cobalt-base-padding/2;

      @include bp(sm,sm)
      {
        margin-top: 0;
        margin-left: $cobalt-base-padding/2;
      }

      @include bpgte(lg)
      {
        margin-top: 0;
        margin-left: $cobalt-base-padding/2;
      }
    }
  }

  &--ace &-directions
  {
    flex-shrink: 0;
  }

  /* postcss-bem-linter: ignore */
  .c-location-hours-additional-text
  {
    margin-top: $cobalt-base-padding/2;
  }

  /* postcss-bem-linter: ignore */
  .c-phone
  {
    display: flex;
  }

  /* postcss-bem-linter: ignore */
  .c-phone-label
  {
    margin-right: 1ex;
    font-weight: $font-weight-bold;
  }

  /* postcss-bem-linter: ignore */
  .c-map-with-pins
  {
    margin-top: $cobalt-base-padding;

    @include bpgte(md)
    {
      margin-top: 0;
    }
  }

  /* postcss-bem-linter: ignore */
  .c-map-with-pins,
  .location-map-wrapper
  {
    min-height: 150px;
    width: 100%;

    @include bp(sm,sm)
    {
      height: 150px;
    }

    @include bpgte(md)
    {
      height: 100%;
    }
  }

  /* postcss-bem-linter: ignore */
  .c-location-hours-title
  {
    display: none;
  }

  &--ace &-title,
  &--ace &-hoursToday
  {
    text-align: center;
  }

  &--ace &-reviews
  {
    display: flex;
    justify-content: center;
    margin-top: $cobalt-base-padding/2;
  }

  &--ace &-col--full
  {
    @include l-col($grid-columns, $grid-columns);
  }

  &--ace &-col--secondary
  {
    @include l-col($grid-columns, $grid-columns);

    @include bp(sm,sm)
    {
      @include l-col($grid-columns/2, $grid-columns);
    }

    @include bpgte(md)
    {
      @include l-col($grid-columns/3, $grid-columns);
    }

    &:not(:first-child)
    {
      margin-top: $cobalt-base-padding;

      @include bpgte(sm)
      {
        margin-top: 0;
      }
    }

    &:last-child
    {
      display: none;

      @include bp(sm,sm)
      {
        @include l-col($grid-columns, $grid-columns);

        display: flex;
      }

      @include bpgte(md)
      {
        @include l-col($grid-columns/3, $grid-columns);

        display: flex;
      }
    }
  }

  /* postcss-bem-linter: ignore */
  &--ace &-title,
  &--ace &-colHeading,
  &--ace &-address,
  .c-phone
  {
    margin-bottom: $cobalt-base-padding/2;
  }

  &--ace &-phones--single
  {
    /* postcss-bem-linter: ignore */
    .c-phone-label
    {
      display: none;
    }
  }
}
