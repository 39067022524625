/** @define Product */

// Product Ace
.Product
{
  &--ace
  {
    padding-bottom: $cobalt-base-padding*2;
  }

  &--ace &-row
  {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
  }

  &--ace &-imgWrapper,
  &--ace &-info,
  {
    width: 100%;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }

  &--ace &-title,
  &--ace &-text,
  &--ace &-price,
  &--ace &-linkWrapper
  {
    margin-top: 0.5rem;
  }
}
