// Add any font families here

// Example:
// @font-face {
//   font-family: 'Helvetica Neue Bold Condensed';
//   src: url('helveticaneue-boldcond-webfont.eot');
//   src:
//     url('helveticaneue-boldcond-webfont.eot?#iefix') format("embedded-opentype"),
//     url('helveticaneue-boldcond-webfont.woff') format("woff"),
//     url('helveticaneue-boldcond-webfont.ttf') format("truetype"),
//     url('helveticaneue-boldcond-webfont.svg#helvetica_neueboldcondensed') format("svg");
//   font-weight: $font-weight-regular;
//   font-style: normal;
// }

@font-face
{
  font-family: OpenSans;
  // src: url('OpenSans/OpenSans-Regular.eot');
  src: url('OpenSans/OpenSans-Regular.ttf') format("truetype");
  // src:
  //   url('OpenSans/OpenSans-Regular.eot?#iefix') format("embedded-opentype"),
  //   url('OpenSans/OpenSans-Regular.woff') format("woff"),
  //   url('OpenSans/OpenSans-Regular.ttf') format("truetype"),
  //   url('OpenSans/OpenSans-Regular.svg#OpenSans-Regular') format("svg");
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face
{
  font-family: OpenSans;
  // src: url('OpenSans/OpenSans-Italic.eot');
  src: url('OpenSans/OpenSans-Italic.ttf') format("truetype");
  // src:
  //   url('OpenSans/OpenSans-Italic.eot?#iefix') format("embedded-opentype"),
  //   url('OpenSans/OpenSans-Italic.woff') format("woff"),
  //   url('OpenSans/OpenSans-Italic.ttf') format("truetype"),
  //   url('OpenSans/OpenSans-Italic.svg#OpenSans-Italic') format("svg");
  font-weight: $font-weight-regular;
  font-style: italic;
}

@font-face
{
  font-family: OpenSans;
  // src: url('OpenSans/OpenSans-Bold.eot');
  src: url('OpenSans/OpenSans-Bold.ttf') format("truetype");
  // src:
  //   url('OpenSans/OpenSans-Bold.eot?#iefix') format("embedded-opentype"),
  //   url('OpenSans/OpenSans-Bold.woff') format("woff"),
  //   url('OpenSans/OpenSans-Bold.ttf') format("truetype"),
  //   url('OpenSans/OpenSans-Bold.svg#OpenSans-Bold') format("svg");
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face
{
  font-family: OpenSans;
  // src: url('OpenSans/OpenSans-BoldItalic.eot');
  src: url('OpenSans/OpenSans-BoldItalic.ttf') format("truetype");
  // src:
  //   url('OpenSans/OpenSans-BoldItalic.eot?#iefix') format("embedded-opentype"),
  //   url('OpenSans/OpenSans-BoldItalic.woff') format("woff"),
  //   url('OpenSans/OpenSans-BoldItalic.ttf') format("truetype"),
  //   url('OpenSans/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format("svg");
  font-weight: $font-weight-bold;
  font-style: italic;
}

@font-face
{
  font-family: OpenSans;
  // src: url('OpenSans/OpenSans-SemiBold.eot');
  src: url('OpenSans/OpenSans-SemiBold.ttf') format("truetype");
  // src:
  //   url('OpenSans/OpenSans-SemiBold.eot?#iefix') format("embedded-opentype"),
  //   url('OpenSans/OpenSans-SemiBold.woff') format("woff"),
  //   url('OpenSans/OpenSans-SemiBold.ttf') format("truetype"),
  //   url('OpenSans/OpenSans-SemiBold.svg#OpenSans-SemiBold') format("svg");
  font-weight: $font-weight-semibold;
  font-style: normal;
}

@font-face
{
  font-family: OpenSans;
  // src: url('OpenSans/OpenSans-SemiBoldItalic.eot');
  src: url('OpenSans/OpenSans-SemiBoldItalic.ttf') format("truetype");
  // src:
  //   url('OpenSans/OpenSans-SemiBoldItalic.eot?#iefix') format("embedded-opentype"),
  //   url('OpenSans/OpenSans-SemiBoldItalic.woff') format("woff"),
  //   url('OpenSans/OpenSans-SemiBoldItalic.ttf') format("truetype"),
  //   url('OpenSans/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic') format("svg");
  font-weight: $font-weight-semibold;
  font-style: italic;
}

@font-face
{
  font-family: 'BlenderPro';
  src: url('BlenderPro-Book.eot');
  src: url('BlenderPro-Book.woff2') format("woff2");
  src:
    url('BlenderPro-Book.eot?#iefix') format("embedded-opentype"),
    url('BlenderPro-Book.woff') format("woff"),
    url('BlenderPro-Book.ttf') format("truetype"),
    url('BlenderPro-Book.svg#BlenderPro-Book') format("svg");
  font-weight: $font-weight-normal;
}
